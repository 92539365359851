/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EnvVarValueTypeDto } from './EnvVarValueTypeDto';
import {
    EnvVarValueTypeDtoFromJSON,
    EnvVarValueTypeDtoFromJSONTyped,
    EnvVarValueTypeDtoToJSON,
} from './EnvVarValueTypeDto';

/**
 * 
 * @export
 * @interface SecretKeyEnvVarSpecV1Dto
 */
export interface SecretKeyEnvVarSpecV1Dto {
    /**
     * 
     * @type {EnvVarValueTypeDto}
     * @memberof SecretKeyEnvVarSpecV1Dto
     */
    valueType: EnvVarValueTypeDto;
    /**
     * 
     * @type {string}
     * @memberof SecretKeyEnvVarSpecV1Dto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SecretKeyEnvVarSpecV1Dto
     */
    secretGroup: string;
    /**
     * 
     * @type {string}
     * @memberof SecretKeyEnvVarSpecV1Dto
     */
    secretKey: string;
}

/**
 * Check if a given object implements the SecretKeyEnvVarSpecV1Dto interface.
 */
export function instanceOfSecretKeyEnvVarSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "valueType" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "secretGroup" in value;
    isInstance = isInstance && "secretKey" in value;

    return isInstance;
}

export function SecretKeyEnvVarSpecV1DtoFromJSON(json: any): SecretKeyEnvVarSpecV1Dto {
    return SecretKeyEnvVarSpecV1DtoFromJSONTyped(json, false);
}

export function SecretKeyEnvVarSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecretKeyEnvVarSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'valueType': EnvVarValueTypeDtoFromJSON(json['valueType']),
        'name': json['name'],
        'secretGroup': json['secretGroup'],
        'secretKey': json['secretKey'],
    };
}

export function SecretKeyEnvVarSpecV1DtoToJSON(value?: SecretKeyEnvVarSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valueType': EnvVarValueTypeDtoToJSON(value.valueType),
        'name': value.name,
        'secretGroup': value.secretGroup,
        'secretKey': value.secretKey,
    };
}

