/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RepositoryV1Dto
 */
export interface RepositoryV1Dto {
    /**
     * 
     * @type {string}
     * @memberof RepositoryV1Dto
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryV1Dto
     */
    url: string;
}

/**
 * Check if a given object implements the RepositoryV1Dto interface.
 */
export function instanceOfRepositoryV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "url" in value;

    return isInstance;
}

export function RepositoryV1DtoFromJSON(json: any): RepositoryV1Dto {
    return RepositoryV1DtoFromJSONTyped(json, false);
}

export function RepositoryV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepositoryV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayName': json['displayName'],
        'url': json['url'],
    };
}

export function RepositoryV1DtoToJSON(value?: RepositoryV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayName': value.displayName,
        'url': value.url,
    };
}

