/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HarborEventV1EventDataDto } from './HarborEventV1EventDataDto';
import {
    HarborEventV1EventDataDtoFromJSON,
    HarborEventV1EventDataDtoFromJSONTyped,
    HarborEventV1EventDataDtoToJSON,
} from './HarborEventV1EventDataDto';

/**
 * 
 * @export
 * @interface HarborEventV1Dto
 */
export interface HarborEventV1Dto {
    /**
     * 
     * @type {HarborEventV1EventDataDto}
     * @memberof HarborEventV1Dto
     */
    eventData: HarborEventV1EventDataDto;
}

/**
 * Check if a given object implements the HarborEventV1Dto interface.
 */
export function instanceOfHarborEventV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "eventData" in value;

    return isInstance;
}

export function HarborEventV1DtoFromJSON(json: any): HarborEventV1Dto {
    return HarborEventV1DtoFromJSONTyped(json, false);
}

export function HarborEventV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HarborEventV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventData': HarborEventV1EventDataDtoFromJSON(json['event_data']),
    };
}

export function HarborEventV1DtoToJSON(value?: HarborEventV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event_data': HarborEventV1EventDataDtoToJSON(value.eventData),
    };
}

