/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { APIVersionV1Dto } from './APIVersionV1Dto';
import {
    APIVersionV1DtoFromJSON,
    APIVersionV1DtoFromJSONTyped,
    APIVersionV1DtoToJSON,
} from './APIVersionV1Dto';
import type { GithubRepositoryTemplateSpecV1Dto } from './GithubRepositoryTemplateSpecV1Dto';
import {
    GithubRepositoryTemplateSpecV1DtoFromJSON,
    GithubRepositoryTemplateSpecV1DtoFromJSONTyped,
    GithubRepositoryTemplateSpecV1DtoToJSON,
} from './GithubRepositoryTemplateSpecV1Dto';
import type { ObjectMetaV1Dto } from './ObjectMetaV1Dto';
import {
    ObjectMetaV1DtoFromJSON,
    ObjectMetaV1DtoFromJSONTyped,
    ObjectMetaV1DtoToJSON,
} from './ObjectMetaV1Dto';

/**
 * 
 * @export
 * @interface GithubRepositoryTemplateV1Dto
 */
export interface GithubRepositoryTemplateV1Dto {
    /**
     * 
     * @type {APIVersionV1Dto}
     * @memberof GithubRepositoryTemplateV1Dto
     */
    apiVersion: APIVersionV1Dto;
    /**
     * 
     * @type {string}
     * @memberof GithubRepositoryTemplateV1Dto
     */
    kind: GithubRepositoryTemplateV1DtoKindEnum;
    /**
     * 
     * @type {ObjectMetaV1Dto}
     * @memberof GithubRepositoryTemplateV1Dto
     */
    metadata: ObjectMetaV1Dto;
    /**
     * 
     * @type {GithubRepositoryTemplateSpecV1Dto}
     * @memberof GithubRepositoryTemplateV1Dto
     */
    spec: GithubRepositoryTemplateSpecV1Dto;
    /**
     * 
     * @type {object}
     * @memberof GithubRepositoryTemplateV1Dto
     */
    readonly status?: object;
}


/**
 * @export
 */
export const GithubRepositoryTemplateV1DtoKindEnum = {
    GithubRepositoryTemplate: 'GithubRepositoryTemplate'
} as const;
export type GithubRepositoryTemplateV1DtoKindEnum = typeof GithubRepositoryTemplateV1DtoKindEnum[keyof typeof GithubRepositoryTemplateV1DtoKindEnum];


/**
 * Check if a given object implements the GithubRepositoryTemplateV1Dto interface.
 */
export function instanceOfGithubRepositoryTemplateV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "apiVersion" in value;
    isInstance = isInstance && "kind" in value;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "spec" in value;

    return isInstance;
}

export function GithubRepositoryTemplateV1DtoFromJSON(json: any): GithubRepositoryTemplateV1Dto {
    return GithubRepositoryTemplateV1DtoFromJSONTyped(json, false);
}

export function GithubRepositoryTemplateV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GithubRepositoryTemplateV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiVersion': APIVersionV1DtoFromJSON(json['apiVersion']),
        'kind': json['kind'],
        'metadata': ObjectMetaV1DtoFromJSON(json['metadata']),
        'spec': GithubRepositoryTemplateSpecV1DtoFromJSON(json['spec']),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function GithubRepositoryTemplateV1DtoToJSON(value?: GithubRepositoryTemplateV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apiVersion': APIVersionV1DtoToJSON(value.apiVersion),
        'kind': value.kind,
        'metadata': ObjectMetaV1DtoToJSON(value.metadata),
        'spec': GithubRepositoryTemplateSpecV1DtoToJSON(value.spec),
    };
}

