/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InfraBrokerSpecV1ConstraintsDto
 */
export interface InfraBrokerSpecV1ConstraintsDto {
    /**
     * 
     * @type {string}
     * @memberof InfraBrokerSpecV1ConstraintsDto
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof InfraBrokerSpecV1ConstraintsDto
     */
    environment: InfraBrokerSpecV1ConstraintsDtoEnvironmentEnum;
}


/**
 * @export
 */
export const InfraBrokerSpecV1ConstraintsDtoEnvironmentEnum = {
    Dev: 'DEV',
    Prod: 'PROD'
} as const;
export type InfraBrokerSpecV1ConstraintsDtoEnvironmentEnum = typeof InfraBrokerSpecV1ConstraintsDtoEnvironmentEnum[keyof typeof InfraBrokerSpecV1ConstraintsDtoEnvironmentEnum];


/**
 * Check if a given object implements the InfraBrokerSpecV1ConstraintsDto interface.
 */
export function instanceOfInfraBrokerSpecV1ConstraintsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "environment" in value;

    return isInstance;
}

export function InfraBrokerSpecV1ConstraintsDtoFromJSON(json: any): InfraBrokerSpecV1ConstraintsDto {
    return InfraBrokerSpecV1ConstraintsDtoFromJSONTyped(json, false);
}

export function InfraBrokerSpecV1ConstraintsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfraBrokerSpecV1ConstraintsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'region': json['region'],
        'environment': json['environment'],
    };
}

export function InfraBrokerSpecV1ConstraintsDtoToJSON(value?: InfraBrokerSpecV1ConstraintsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'region': value.region,
        'environment': value.environment,
    };
}

