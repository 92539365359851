/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApplicationQuickstartPipelineSpecV1ApplicationTemplateDto } from './ApplicationQuickstartPipelineSpecV1ApplicationTemplateDto';
import {
    ApplicationQuickstartPipelineSpecV1ApplicationTemplateDtoFromJSON,
    ApplicationQuickstartPipelineSpecV1ApplicationTemplateDtoFromJSONTyped,
    ApplicationQuickstartPipelineSpecV1ApplicationTemplateDtoToJSON,
} from './ApplicationQuickstartPipelineSpecV1ApplicationTemplateDto';
import type { ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDto } from './ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDto';
import {
    ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDtoFromJSON,
    ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDtoFromJSONTyped,
    ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDtoToJSON,
} from './ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDto';

/**
 * 
 * @export
 * @interface ApplicationQuickstartPipelineSpecV1Dto
 */
export interface ApplicationQuickstartPipelineSpecV1Dto {
    /**
     * 
     * @type {ApplicationQuickstartPipelineSpecV1ApplicationTemplateDto}
     * @memberof ApplicationQuickstartPipelineSpecV1Dto
     */
    applicationTemplate: ApplicationQuickstartPipelineSpecV1ApplicationTemplateDto;
    /**
     * 
     * @type {ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDto}
     * @memberof ApplicationQuickstartPipelineSpecV1Dto
     */
    githubRepositoryTemplate: ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDto;
    /**
     * 
     * @type {string}
     * @memberof ApplicationQuickstartPipelineSpecV1Dto
     */
    region: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationQuickstartPipelineSpecV1Dto
     */
    createTeam?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationQuickstartPipelineSpecV1Dto
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationQuickstartPipelineSpecV1Dto
     */
    macAppId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationQuickstartPipelineSpecV1Dto
     */
    macPlatformId?: string;
}

/**
 * Check if a given object implements the ApplicationQuickstartPipelineSpecV1Dto interface.
 */
export function instanceOfApplicationQuickstartPipelineSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "applicationTemplate" in value;
    isInstance = isInstance && "githubRepositoryTemplate" in value;
    isInstance = isInstance && "region" in value;

    return isInstance;
}

export function ApplicationQuickstartPipelineSpecV1DtoFromJSON(json: any): ApplicationQuickstartPipelineSpecV1Dto {
    return ApplicationQuickstartPipelineSpecV1DtoFromJSONTyped(json, false);
}

export function ApplicationQuickstartPipelineSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationQuickstartPipelineSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationTemplate': ApplicationQuickstartPipelineSpecV1ApplicationTemplateDtoFromJSON(json['applicationTemplate']),
        'githubRepositoryTemplate': ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDtoFromJSON(json['githubRepositoryTemplate']),
        'region': json['region'],
        'createTeam': !exists(json, 'createTeam') ? undefined : json['createTeam'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'macAppId': !exists(json, 'macAppId') ? undefined : json['macAppId'],
        'macPlatformId': !exists(json, 'macPlatformId') ? undefined : json['macPlatformId'],
    };
}

export function ApplicationQuickstartPipelineSpecV1DtoToJSON(value?: ApplicationQuickstartPipelineSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationTemplate': ApplicationQuickstartPipelineSpecV1ApplicationTemplateDtoToJSON(value.applicationTemplate),
        'githubRepositoryTemplate': ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDtoToJSON(value.githubRepositoryTemplate),
        'region': value.region,
        'createTeam': value.createTeam,
        'displayName': value.displayName,
        'macAppId': value.macAppId,
        'macPlatformId': value.macPlatformId,
    };
}

