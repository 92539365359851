/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ObjectRefV1Dto } from './ObjectRefV1Dto';
import {
    ObjectRefV1DtoFromJSON,
    ObjectRefV1DtoFromJSONTyped,
    ObjectRefV1DtoToJSON,
} from './ObjectRefV1Dto';
import type { ServicePipelineSpecV1DeployDto } from './ServicePipelineSpecV1DeployDto';
import {
    ServicePipelineSpecV1DeployDtoFromJSON,
    ServicePipelineSpecV1DeployDtoFromJSONTyped,
    ServicePipelineSpecV1DeployDtoToJSON,
} from './ServicePipelineSpecV1DeployDto';

/**
 * 
 * @export
 * @interface ServicePipelineSpecV1Dto
 */
export interface ServicePipelineSpecV1Dto {
    /**
     * 
     * @type {ObjectRefV1Dto}
     * @memberof ServicePipelineSpecV1Dto
     */
    serviceRef: ObjectRefV1Dto;
    /**
     * 
     * @type {string}
     * @memberof ServicePipelineSpecV1Dto
     */
    type: ServicePipelineSpecV1DtoTypeEnum;
    /**
     * 
     * @type {ServicePipelineSpecV1DeployDto}
     * @memberof ServicePipelineSpecV1Dto
     */
    deploy?: ServicePipelineSpecV1DeployDto;
}


/**
 * @export
 */
export const ServicePipelineSpecV1DtoTypeEnum = {
    Deploy: 'DEPLOY'
} as const;
export type ServicePipelineSpecV1DtoTypeEnum = typeof ServicePipelineSpecV1DtoTypeEnum[keyof typeof ServicePipelineSpecV1DtoTypeEnum];


/**
 * Check if a given object implements the ServicePipelineSpecV1Dto interface.
 */
export function instanceOfServicePipelineSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "serviceRef" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function ServicePipelineSpecV1DtoFromJSON(json: any): ServicePipelineSpecV1Dto {
    return ServicePipelineSpecV1DtoFromJSONTyped(json, false);
}

export function ServicePipelineSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServicePipelineSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceRef': ObjectRefV1DtoFromJSON(json['serviceRef']),
        'type': json['type'],
        'deploy': !exists(json, 'deploy') ? undefined : ServicePipelineSpecV1DeployDtoFromJSON(json['deploy']),
    };
}

export function ServicePipelineSpecV1DtoToJSON(value?: ServicePipelineSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceRef': ObjectRefV1DtoToJSON(value.serviceRef),
        'type': value.type,
        'deploy': ServicePipelineSpecV1DeployDtoToJSON(value.deploy),
    };
}

