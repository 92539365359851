/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DatabaseTypeV1Dto } from './DatabaseTypeV1Dto';
import {
    DatabaseTypeV1DtoFromJSON,
    DatabaseTypeV1DtoFromJSONTyped,
    DatabaseTypeV1DtoToJSON,
} from './DatabaseTypeV1Dto';

/**
 * Unmanaged Postgres Database specification
 * @export
 * @interface UnmanagedPostgresDatabaseSpecV1Dto
 */
export interface UnmanagedPostgresDatabaseSpecV1Dto {
    /**
     * 
     * @type {DatabaseTypeV1Dto}
     * @memberof UnmanagedPostgresDatabaseSpecV1Dto
     */
    databaseType: DatabaseTypeV1Dto;
    /**
     * The storage size in Gb. Must be a power of 2 starting from 32 GB.
     * @type {number}
     * @memberof UnmanagedPostgresDatabaseSpecV1Dto
     */
    storageGb: number;
    /**
     * The backup retention days
     * @type {number}
     * @memberof UnmanagedPostgresDatabaseSpecV1Dto
     */
    backupRetentionDays: number;
    /**
     * The name of the sku, e.g. Standard_B1ms. This should come from the compute plans endpoint.
     * @type {string}
     * @memberof UnmanagedPostgresDatabaseSpecV1Dto
     */
    computePlan: string;
    /**
     * The region of the database, e.g. westeurope. This should come from the regions endpoint.
     * @type {string}
     * @memberof UnmanagedPostgresDatabaseSpecV1Dto
     */
    region: string;
    /**
     * The name of the database
     * @type {string}
     * @memberof UnmanagedPostgresDatabaseSpecV1Dto
     */
    databaseName: string;
    /**
     * Maximum number of connections allowed to the database
     * @type {number}
     * @memberof UnmanagedPostgresDatabaseSpecV1Dto
     */
    maxConnections: number;
    /**
     * Reference to the backup using which the new database needs to be created.
     * @type {string}
     * @memberof UnmanagedPostgresDatabaseSpecV1Dto
     */
    backupRef?: string;
    /**
     * Whether database is active or not
     * @type {boolean}
     * @memberof UnmanagedPostgresDatabaseSpecV1Dto
     */
    active: boolean;
    /**
     * Display name of the database
     * @type {string}
     * @memberof UnmanagedPostgresDatabaseSpecV1Dto
     */
    displayName: string;
    /**
     * Flexible Postgres Server name
     * @type {string}
     * @memberof UnmanagedPostgresDatabaseSpecV1Dto
     */
    readonly serverName?: string;
    /**
     * Secret path for db credentials
     * @type {string}
     * @memberof UnmanagedPostgresDatabaseSpecV1Dto
     */
    readonly secretPath?: string;
    /**
     * URL to create a support request for database
     * @type {string}
     * @memberof UnmanagedPostgresDatabaseSpecV1Dto
     */
    readonly supportRequestUrl?: string;
}

/**
 * Check if a given object implements the UnmanagedPostgresDatabaseSpecV1Dto interface.
 */
export function instanceOfUnmanagedPostgresDatabaseSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "databaseType" in value;
    isInstance = isInstance && "storageGb" in value;
    isInstance = isInstance && "backupRetentionDays" in value;
    isInstance = isInstance && "computePlan" in value;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "databaseName" in value;
    isInstance = isInstance && "maxConnections" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "displayName" in value;

    return isInstance;
}

export function UnmanagedPostgresDatabaseSpecV1DtoFromJSON(json: any): UnmanagedPostgresDatabaseSpecV1Dto {
    return UnmanagedPostgresDatabaseSpecV1DtoFromJSONTyped(json, false);
}

export function UnmanagedPostgresDatabaseSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnmanagedPostgresDatabaseSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'databaseType': DatabaseTypeV1DtoFromJSON(json['databaseType']),
        'storageGb': json['storageGb'],
        'backupRetentionDays': json['backupRetentionDays'],
        'computePlan': json['computePlan'],
        'region': json['region'],
        'databaseName': json['databaseName'],
        'maxConnections': json['maxConnections'],
        'backupRef': !exists(json, 'backupRef') ? undefined : json['backupRef'],
        'active': json['active'],
        'displayName': json['displayName'],
        'serverName': !exists(json, 'serverName') ? undefined : json['serverName'],
        'secretPath': !exists(json, 'secretPath') ? undefined : json['secretPath'],
        'supportRequestUrl': !exists(json, 'supportRequestUrl') ? undefined : json['supportRequestUrl'],
    };
}

export function UnmanagedPostgresDatabaseSpecV1DtoToJSON(value?: UnmanagedPostgresDatabaseSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'databaseType': DatabaseTypeV1DtoToJSON(value.databaseType),
        'storageGb': value.storageGb,
        'backupRetentionDays': value.backupRetentionDays,
        'computePlan': value.computePlan,
        'region': value.region,
        'databaseName': value.databaseName,
        'maxConnections': value.maxConnections,
        'backupRef': value.backupRef,
        'active': value.active,
        'displayName': value.displayName,
    };
}

