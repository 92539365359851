/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyRegexDto } from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyRegexDto';
import {
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyRegexDtoFromJSON,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyRegexDtoFromJSONTyped,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyRegexDtoToJSON,
} from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyRegexDto';

/**
 * 
 * @export
 * @interface GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto
 */
export interface GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto {
    /**
     * 
     * @type {string}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto
     */
    type: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoTypeEnum;
    /**
     * 
     * @type {GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyRegexDto}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto
     */
    regex?: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyRegexDto;
}


/**
 * @export
 */
export const GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoTypeEnum = {
    Manual: 'MANUAL',
    Regex: 'REGEX'
} as const;
export type GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoTypeEnum = typeof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoTypeEnum[keyof typeof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoTypeEnum];


/**
 * Check if a given object implements the GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto interface.
 */
export function instanceOfGithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoFromJSON(json: any): GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto {
    return GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoFromJSONTyped(json, false);
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'regex': !exists(json, 'regex') ? undefined : GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyRegexDtoFromJSON(json['regex']),
    };
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoToJSON(value?: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'regex': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyRegexDtoToJSON(value.regex),
    };
}

