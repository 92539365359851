/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HarborEventV1EventDataRepositoryDto
 */
export interface HarborEventV1EventDataRepositoryDto {
    /**
     * 
     * @type {string}
     * @memberof HarborEventV1EventDataRepositoryDto
     */
    namespace: string;
}

/**
 * Check if a given object implements the HarborEventV1EventDataRepositoryDto interface.
 */
export function instanceOfHarborEventV1EventDataRepositoryDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "namespace" in value;

    return isInstance;
}

export function HarborEventV1EventDataRepositoryDtoFromJSON(json: any): HarborEventV1EventDataRepositoryDto {
    return HarborEventV1EventDataRepositoryDtoFromJSONTyped(json, false);
}

export function HarborEventV1EventDataRepositoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HarborEventV1EventDataRepositoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'namespace': json['namespace'],
    };
}

export function HarborEventV1EventDataRepositoryDtoToJSON(value?: HarborEventV1EventDataRepositoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'namespace': value.namespace,
    };
}

