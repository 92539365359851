/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PipelineSpecV1Dto
 */
export interface PipelineSpecV1Dto {
    /**
     * 
     * @type {string}
     * @memberof PipelineSpecV1Dto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineSpecV1Dto
     */
    runId?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineSpecV1Dto
     */
    type: PipelineSpecV1DtoTypeEnum;
}


/**
 * @export
 */
export const PipelineSpecV1DtoTypeEnum = {
    BlankApplicationCreation: 'BLANK_APPLICATION_CREATION',
    RetinaAccountCreation: 'RETINA_ACCOUNT_CREATION',
    RetinaAccountUpdate: 'RETINA_ACCOUNT_UPDATE',
    RetinaAccountDeletion: 'RETINA_ACCOUNT_DELETION',
    RetinaSchemaCreation: 'RETINA_SCHEMA_CREATION',
    RetinaSchemaUpdate: 'RETINA_SCHEMA_UPDATE',
    RetinaSchemaDeletion: 'RETINA_SCHEMA_DELETION',
    RetinaTopicCreation: 'RETINA_TOPIC_CREATION',
    RetinaTopicUpdate: 'RETINA_TOPIC_UPDATE',
    RetinaTopicDeletion: 'RETINA_TOPIC_DELETION'
} as const;
export type PipelineSpecV1DtoTypeEnum = typeof PipelineSpecV1DtoTypeEnum[keyof typeof PipelineSpecV1DtoTypeEnum];


/**
 * Check if a given object implements the PipelineSpecV1Dto interface.
 */
export function instanceOfPipelineSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function PipelineSpecV1DtoFromJSON(json: any): PipelineSpecV1Dto {
    return PipelineSpecV1DtoFromJSONTyped(json, false);
}

export function PipelineSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'runId': !exists(json, 'runId') ? undefined : json['runId'],
        'type': json['type'],
    };
}

export function PipelineSpecV1DtoToJSON(value?: PipelineSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'runId': value.runId,
        'type': value.type,
    };
}

