/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DatabaseBackupV1Dto } from './DatabaseBackupV1Dto';
import {
    DatabaseBackupV1DtoFromJSON,
    DatabaseBackupV1DtoFromJSONTyped,
    DatabaseBackupV1DtoToJSON,
} from './DatabaseBackupV1Dto';

/**
 * 
 * @export
 * @interface DatabaseBackupPageV1Dto
 */
export interface DatabaseBackupPageV1Dto {
    /**
     * 
     * @type {number}
     * @memberof DatabaseBackupPageV1Dto
     */
    number: number;
    /**
     * 
     * @type {number}
     * @memberof DatabaseBackupPageV1Dto
     */
    size: number;
    /**
     * 
     * @type {number}
     * @memberof DatabaseBackupPageV1Dto
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof DatabaseBackupPageV1Dto
     */
    totalElements: number;
    /**
     * 
     * @type {boolean}
     * @memberof DatabaseBackupPageV1Dto
     */
    first: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DatabaseBackupPageV1Dto
     */
    last: boolean;
    /**
     * 
     * @type {Array<DatabaseBackupV1Dto>}
     * @memberof DatabaseBackupPageV1Dto
     */
    content: Array<DatabaseBackupV1Dto>;
}

/**
 * Check if a given object implements the DatabaseBackupPageV1Dto interface.
 */
export function instanceOfDatabaseBackupPageV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "totalPages" in value;
    isInstance = isInstance && "totalElements" in value;
    isInstance = isInstance && "first" in value;
    isInstance = isInstance && "last" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function DatabaseBackupPageV1DtoFromJSON(json: any): DatabaseBackupPageV1Dto {
    return DatabaseBackupPageV1DtoFromJSONTyped(json, false);
}

export function DatabaseBackupPageV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatabaseBackupPageV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'size': json['size'],
        'totalPages': json['totalPages'],
        'totalElements': json['totalElements'],
        'first': json['first'],
        'last': json['last'],
        'content': ((json['content'] as Array<any>).map(DatabaseBackupV1DtoFromJSON)),
    };
}

export function DatabaseBackupPageV1DtoToJSON(value?: DatabaseBackupPageV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'size': value.size,
        'totalPages': value.totalPages,
        'totalElements': value.totalElements,
        'first': value.first,
        'last': value.last,
        'content': ((value.content as Array<any>).map(DatabaseBackupV1DtoToJSON)),
    };
}

