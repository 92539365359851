/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EnvVarSpecV1Dto } from './EnvVarSpecV1Dto';
import {
    EnvVarSpecV1DtoFromJSON,
    EnvVarSpecV1DtoFromJSONTyped,
    EnvVarSpecV1DtoToJSON,
} from './EnvVarSpecV1Dto';
import type { RevisionTemplateSpecV1HealthCheckDto } from './RevisionTemplateSpecV1HealthCheckDto';
import {
    RevisionTemplateSpecV1HealthCheckDtoFromJSON,
    RevisionTemplateSpecV1HealthCheckDtoFromJSONTyped,
    RevisionTemplateSpecV1HealthCheckDtoToJSON,
} from './RevisionTemplateSpecV1HealthCheckDto';
import type { RevisionTemplateSpecV1ObservabilityDto } from './RevisionTemplateSpecV1ObservabilityDto';
import {
    RevisionTemplateSpecV1ObservabilityDtoFromJSON,
    RevisionTemplateSpecV1ObservabilityDtoFromJSONTyped,
    RevisionTemplateSpecV1ObservabilityDtoToJSON,
} from './RevisionTemplateSpecV1ObservabilityDto';
import type { RevisionTemplateSpecV1ResourcesDto } from './RevisionTemplateSpecV1ResourcesDto';
import {
    RevisionTemplateSpecV1ResourcesDtoFromJSON,
    RevisionTemplateSpecV1ResourcesDtoFromJSONTyped,
    RevisionTemplateSpecV1ResourcesDtoToJSON,
} from './RevisionTemplateSpecV1ResourcesDto';
import type { ScalingSpecV1Dto } from './ScalingSpecV1Dto';
import {
    ScalingSpecV1DtoFromJSON,
    ScalingSpecV1DtoFromJSONTyped,
    ScalingSpecV1DtoToJSON,
} from './ScalingSpecV1Dto';
import type { SecretFileMountSpecV1Dto } from './SecretFileMountSpecV1Dto';
import {
    SecretFileMountSpecV1DtoFromJSON,
    SecretFileMountSpecV1DtoFromJSONTyped,
    SecretFileMountSpecV1DtoToJSON,
} from './SecretFileMountSpecV1Dto';

/**
 * 
 * @export
 * @interface RevisionTemplateSpecV1Dto
 */
export interface RevisionTemplateSpecV1Dto {
    /**
     * 
     * @type {string}
     * @memberof RevisionTemplateSpecV1Dto
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof RevisionTemplateSpecV1Dto
     */
    containerImageName: string;
    /**
     * 
     * @type {ScalingSpecV1Dto}
     * @memberof RevisionTemplateSpecV1Dto
     */
    replicaScaling: ScalingSpecV1Dto;
    /**
     * 
     * @type {RevisionTemplateSpecV1ResourcesDto}
     * @memberof RevisionTemplateSpecV1Dto
     */
    resources: RevisionTemplateSpecV1ResourcesDto;
    /**
     * 
     * @type {RevisionTemplateSpecV1HealthCheckDto}
     * @memberof RevisionTemplateSpecV1Dto
     */
    healthCheck: RevisionTemplateSpecV1HealthCheckDto;
    /**
     * 
     * @type {RevisionTemplateSpecV1ObservabilityDto}
     * @memberof RevisionTemplateSpecV1Dto
     */
    observability: RevisionTemplateSpecV1ObservabilityDto;
    /**
     * 
     * @type {number}
     * @memberof RevisionTemplateSpecV1Dto
     */
    exposedPort?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RevisionTemplateSpecV1Dto
     */
    acceptExternalTraffic: boolean;
    /**
     * 
     * @type {string}
     * @memberof RevisionTemplateSpecV1Dto
     */
    externalTrafficAuthentication?: RevisionTemplateSpecV1DtoExternalTrafficAuthenticationEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RevisionTemplateSpecV1Dto
     */
    spotComputeEnabled: boolean;
    /**
     * Mount path is map key and resource ID
     * @type {{ [key: string]: SecretFileMountSpecV1Dto | undefined; }}
     * @memberof RevisionTemplateSpecV1Dto
     */
    secretFileMounts?: { [key: string]: SecretFileMountSpecV1Dto | undefined; };
    /**
     * Environment variable name is map key and resource ID
     * @type {{ [key: string]: EnvVarSpecV1Dto | undefined; }}
     * @memberof RevisionTemplateSpecV1Dto
     */
    envVars?: { [key: string]: EnvVarSpecV1Dto | undefined; };
    /**
     * 
     * @type {Array<string>}
     * @memberof RevisionTemplateSpecV1Dto
     */
    containerRunCommand?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RevisionTemplateSpecV1Dto
     */
    vaultProxyEnabled?: boolean;
}


/**
 * @export
 */
export const RevisionTemplateSpecV1DtoExternalTrafficAuthenticationEnum = {
    AzureAuth: 'AZURE_AUTH',
    BasicAuth: 'BASIC_AUTH',
    NoAuth: 'NO_AUTH'
} as const;
export type RevisionTemplateSpecV1DtoExternalTrafficAuthenticationEnum = typeof RevisionTemplateSpecV1DtoExternalTrafficAuthenticationEnum[keyof typeof RevisionTemplateSpecV1DtoExternalTrafficAuthenticationEnum];


/**
 * Check if a given object implements the RevisionTemplateSpecV1Dto interface.
 */
export function instanceOfRevisionTemplateSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "containerImageName" in value;
    isInstance = isInstance && "replicaScaling" in value;
    isInstance = isInstance && "resources" in value;
    isInstance = isInstance && "healthCheck" in value;
    isInstance = isInstance && "observability" in value;
    isInstance = isInstance && "acceptExternalTraffic" in value;
    isInstance = isInstance && "spotComputeEnabled" in value;

    return isInstance;
}

export function RevisionTemplateSpecV1DtoFromJSON(json: any): RevisionTemplateSpecV1Dto {
    return RevisionTemplateSpecV1DtoFromJSONTyped(json, false);
}

export function RevisionTemplateSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RevisionTemplateSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'region': json['region'],
        'containerImageName': json['containerImageName'],
        'replicaScaling': ScalingSpecV1DtoFromJSON(json['replicaScaling']),
        'resources': RevisionTemplateSpecV1ResourcesDtoFromJSON(json['resources']),
        'healthCheck': RevisionTemplateSpecV1HealthCheckDtoFromJSON(json['healthCheck']),
        'observability': RevisionTemplateSpecV1ObservabilityDtoFromJSON(json['observability']),
        'exposedPort': !exists(json, 'exposedPort') ? undefined : json['exposedPort'],
        'acceptExternalTraffic': json['acceptExternalTraffic'],
        'externalTrafficAuthentication': !exists(json, 'externalTrafficAuthentication') ? undefined : json['externalTrafficAuthentication'],
        'spotComputeEnabled': json['spotComputeEnabled'],
        'secretFileMounts': !exists(json, 'secretFileMounts') ? undefined : (mapValues(json['secretFileMounts'], SecretFileMountSpecV1DtoFromJSON)),
        'envVars': !exists(json, 'envVars') ? undefined : (mapValues(json['envVars'], EnvVarSpecV1DtoFromJSON)),
        'containerRunCommand': !exists(json, 'containerRunCommand') ? undefined : json['containerRunCommand'],
        'vaultProxyEnabled': !exists(json, 'vaultProxyEnabled') ? undefined : json['vaultProxyEnabled'],
    };
}

export function RevisionTemplateSpecV1DtoToJSON(value?: RevisionTemplateSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'region': value.region,
        'containerImageName': value.containerImageName,
        'replicaScaling': ScalingSpecV1DtoToJSON(value.replicaScaling),
        'resources': RevisionTemplateSpecV1ResourcesDtoToJSON(value.resources),
        'healthCheck': RevisionTemplateSpecV1HealthCheckDtoToJSON(value.healthCheck),
        'observability': RevisionTemplateSpecV1ObservabilityDtoToJSON(value.observability),
        'exposedPort': value.exposedPort,
        'acceptExternalTraffic': value.acceptExternalTraffic,
        'externalTrafficAuthentication': value.externalTrafficAuthentication,
        'spotComputeEnabled': value.spotComputeEnabled,
        'secretFileMounts': value.secretFileMounts === undefined ? undefined : (mapValues(value.secretFileMounts, SecretFileMountSpecV1DtoToJSON)),
        'envVars': value.envVars === undefined ? undefined : (mapValues(value.envVars, EnvVarSpecV1DtoToJSON)),
        'containerRunCommand': value.containerRunCommand,
        'vaultProxyEnabled': value.vaultProxyEnabled,
    };
}

