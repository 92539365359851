/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The data security classification of the topic. Defines the sensitivity of the data in the topic according to the Maersk data classification policy.
 * Public data is open to all applications, internal data is restricted to the application, and confidential data is restricted to the application and Maersk.
 * 
 * @export
 */
export const DataSecurityClassificationV1Dto = {
    Public: 'PUBLIC',
    Internal: 'INTERNAL',
    Confidential: 'CONFIDENTIAL'
} as const;
export type DataSecurityClassificationV1Dto = typeof DataSecurityClassificationV1Dto[keyof typeof DataSecurityClassificationV1Dto];


export function DataSecurityClassificationV1DtoFromJSON(json: any): DataSecurityClassificationV1Dto {
    return DataSecurityClassificationV1DtoFromJSONTyped(json, false);
}

export function DataSecurityClassificationV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSecurityClassificationV1Dto {
    return json as DataSecurityClassificationV1Dto;
}

export function DataSecurityClassificationV1DtoToJSON(value?: DataSecurityClassificationV1Dto | null): any {
    return value as any;
}

