/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDto
 */
export interface GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDto {
    /**
     * 
     * @type {number}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDto
     */
    initialDelaySeconds: number;
    /**
     * 
     * @type {number}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDto
     */
    periodSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDto
     */
    timeoutSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDto
     */
    failureThreshold: number;
    /**
     * 
     * @type {number}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDto
     */
    successThreshold: number;
}

/**
 * Check if a given object implements the GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDto interface.
 */
export function instanceOfGithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "initialDelaySeconds" in value;
    isInstance = isInstance && "periodSeconds" in value;
    isInstance = isInstance && "timeoutSeconds" in value;
    isInstance = isInstance && "failureThreshold" in value;
    isInstance = isInstance && "successThreshold" in value;

    return isInstance;
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDtoFromJSON(json: any): GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDto {
    return GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDtoFromJSONTyped(json, false);
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'initialDelaySeconds': json['initialDelaySeconds'],
        'periodSeconds': json['periodSeconds'],
        'timeoutSeconds': json['timeoutSeconds'],
        'failureThreshold': json['failureThreshold'],
        'successThreshold': json['successThreshold'],
    };
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDtoToJSON(value?: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'initialDelaySeconds': value.initialDelaySeconds,
        'periodSeconds': value.periodSeconds,
        'timeoutSeconds': value.timeoutSeconds,
        'failureThreshold': value.failureThreshold,
        'successThreshold': value.successThreshold,
    };
}

