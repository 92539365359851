/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDto } from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDto';
import {
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDtoFromJSON,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDtoFromJSONTyped,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDtoToJSON,
} from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDto';
import type { RevisionTemplateSpecV1HealthCheckConfigDto } from './RevisionTemplateSpecV1HealthCheckConfigDto';
import {
    RevisionTemplateSpecV1HealthCheckConfigDtoFromJSON,
    RevisionTemplateSpecV1HealthCheckConfigDtoFromJSONTyped,
    RevisionTemplateSpecV1HealthCheckConfigDtoToJSON,
} from './RevisionTemplateSpecV1HealthCheckConfigDto';
import type { RevisionTemplateSpecV1HealthCheckHttpDto } from './RevisionTemplateSpecV1HealthCheckHttpDto';
import {
    RevisionTemplateSpecV1HealthCheckHttpDtoFromJSON,
    RevisionTemplateSpecV1HealthCheckHttpDtoFromJSONTyped,
    RevisionTemplateSpecV1HealthCheckHttpDtoToJSON,
} from './RevisionTemplateSpecV1HealthCheckHttpDto';
import type { RevisionTemplateSpecV1HealthCheckTcpDto } from './RevisionTemplateSpecV1HealthCheckTcpDto';
import {
    RevisionTemplateSpecV1HealthCheckTcpDtoFromJSON,
    RevisionTemplateSpecV1HealthCheckTcpDtoFromJSONTyped,
    RevisionTemplateSpecV1HealthCheckTcpDtoToJSON,
} from './RevisionTemplateSpecV1HealthCheckTcpDto';

/**
 * 
 * @export
 * @interface RevisionTemplateSpecV1HealthCheckDto
 */
export interface RevisionTemplateSpecV1HealthCheckDto {
    /**
     * 
     * @type {string}
     * @memberof RevisionTemplateSpecV1HealthCheckDto
     */
    type?: RevisionTemplateSpecV1HealthCheckDtoTypeEnum;
    /**
     * 
     * @type {RevisionTemplateSpecV1HealthCheckHttpDto}
     * @memberof RevisionTemplateSpecV1HealthCheckDto
     */
    http?: RevisionTemplateSpecV1HealthCheckHttpDto;
    /**
     * 
     * @type {RevisionTemplateSpecV1HealthCheckTcpDto}
     * @memberof RevisionTemplateSpecV1HealthCheckDto
     */
    tcp?: RevisionTemplateSpecV1HealthCheckTcpDto;
    /**
     * 
     * @type {GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDto}
     * @memberof RevisionTemplateSpecV1HealthCheckDto
     */
    exec?: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDto;
    /**
     * 
     * @type {RevisionTemplateSpecV1HealthCheckConfigDto}
     * @memberof RevisionTemplateSpecV1HealthCheckDto
     */
    config?: RevisionTemplateSpecV1HealthCheckConfigDto;
}


/**
 * @export
 */
export const RevisionTemplateSpecV1HealthCheckDtoTypeEnum = {
    Http: 'HTTP',
    Tcp: 'TCP',
    Exec: 'EXEC'
} as const;
export type RevisionTemplateSpecV1HealthCheckDtoTypeEnum = typeof RevisionTemplateSpecV1HealthCheckDtoTypeEnum[keyof typeof RevisionTemplateSpecV1HealthCheckDtoTypeEnum];


/**
 * Check if a given object implements the RevisionTemplateSpecV1HealthCheckDto interface.
 */
export function instanceOfRevisionTemplateSpecV1HealthCheckDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RevisionTemplateSpecV1HealthCheckDtoFromJSON(json: any): RevisionTemplateSpecV1HealthCheckDto {
    return RevisionTemplateSpecV1HealthCheckDtoFromJSONTyped(json, false);
}

export function RevisionTemplateSpecV1HealthCheckDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RevisionTemplateSpecV1HealthCheckDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'http': !exists(json, 'http') ? undefined : RevisionTemplateSpecV1HealthCheckHttpDtoFromJSON(json['http']),
        'tcp': !exists(json, 'tcp') ? undefined : RevisionTemplateSpecV1HealthCheckTcpDtoFromJSON(json['tcp']),
        'exec': !exists(json, 'exec') ? undefined : GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDtoFromJSON(json['exec']),
        'config': !exists(json, 'config') ? undefined : RevisionTemplateSpecV1HealthCheckConfigDtoFromJSON(json['config']),
    };
}

export function RevisionTemplateSpecV1HealthCheckDtoToJSON(value?: RevisionTemplateSpecV1HealthCheckDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'http': RevisionTemplateSpecV1HealthCheckHttpDtoToJSON(value.http),
        'tcp': RevisionTemplateSpecV1HealthCheckTcpDtoToJSON(value.tcp),
        'exec': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDtoToJSON(value.exec),
        'config': RevisionTemplateSpecV1HealthCheckConfigDtoToJSON(value.config),
    };
}

