/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InfraBrokerRevisionStatusV1Dto } from './InfraBrokerRevisionStatusV1Dto';
import {
    InfraBrokerRevisionStatusV1DtoFromJSON,
    InfraBrokerRevisionStatusV1DtoFromJSONTyped,
    InfraBrokerRevisionStatusV1DtoToJSON,
} from './InfraBrokerRevisionStatusV1Dto';
import type { InfraBrokerStatusSpecV1Dto } from './InfraBrokerStatusSpecV1Dto';
import {
    InfraBrokerStatusSpecV1DtoFromJSON,
    InfraBrokerStatusSpecV1DtoFromJSONTyped,
    InfraBrokerStatusSpecV1DtoToJSON,
} from './InfraBrokerStatusSpecV1Dto';

/**
 * 
 * @export
 * @interface InfraBrokerStatusV1Dto
 */
export interface InfraBrokerStatusV1Dto {
    /**
     * 
     * @type {{ [key: string]: InfraBrokerStatusSpecV1Dto | undefined; }}
     * @memberof InfraBrokerStatusV1Dto
     */
    allocatable: { [key: string]: InfraBrokerStatusSpecV1Dto | undefined; };
    /**
     * 
     * @type {Set<InfraBrokerRevisionStatusV1Dto>}
     * @memberof InfraBrokerStatusV1Dto
     */
    revisions?: Set<InfraBrokerRevisionStatusV1Dto>;
}

/**
 * Check if a given object implements the InfraBrokerStatusV1Dto interface.
 */
export function instanceOfInfraBrokerStatusV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "allocatable" in value;

    return isInstance;
}

export function InfraBrokerStatusV1DtoFromJSON(json: any): InfraBrokerStatusV1Dto {
    return InfraBrokerStatusV1DtoFromJSONTyped(json, false);
}

export function InfraBrokerStatusV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfraBrokerStatusV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allocatable': (mapValues(json['allocatable'], InfraBrokerStatusSpecV1DtoFromJSON)),
        'revisions': !exists(json, 'revisions') ? undefined : (new Set((json['revisions'] as Array<any>).map(InfraBrokerRevisionStatusV1DtoFromJSON))),
    };
}

export function InfraBrokerStatusV1DtoToJSON(value?: InfraBrokerStatusV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allocatable': (mapValues(value.allocatable, InfraBrokerStatusSpecV1DtoToJSON)),
        'revisions': value.revisions === undefined ? undefined : (Array.from(value.revisions as Set<any>).map(InfraBrokerRevisionStatusV1DtoToJSON)),
    };
}

