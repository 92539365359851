/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * ADVANCED. Don't change unless you know what you're doing.
 * The default policy is 'DELETE', which deletes messages based on the retention policy.
 * The 'COMPACT' policy retains the latest message for each key in the topic.
 * 
 * @export
 */
export const TopicCleanupPolicyV1Dto = {
    Delete: 'DELETE',
    Compact: 'COMPACT'
} as const;
export type TopicCleanupPolicyV1Dto = typeof TopicCleanupPolicyV1Dto[keyof typeof TopicCleanupPolicyV1Dto];


export function TopicCleanupPolicyV1DtoFromJSON(json: any): TopicCleanupPolicyV1Dto {
    return TopicCleanupPolicyV1DtoFromJSONTyped(json, false);
}

export function TopicCleanupPolicyV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicCleanupPolicyV1Dto {
    return json as TopicCleanupPolicyV1Dto;
}

export function TopicCleanupPolicyV1DtoToJSON(value?: TopicCleanupPolicyV1Dto | null): any {
    return value as any;
}

