/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GithubRepositoryTemplateSpecV1DefinitionDto } from './GithubRepositoryTemplateSpecV1DefinitionDto';
import {
    GithubRepositoryTemplateSpecV1DefinitionDtoFromJSON,
    GithubRepositoryTemplateSpecV1DefinitionDtoFromJSONTyped,
    GithubRepositoryTemplateSpecV1DefinitionDtoToJSON,
} from './GithubRepositoryTemplateSpecV1DefinitionDto';

/**
 * 
 * @export
 * @interface GithubRepositoryTemplateSpecV1Dto
 */
export interface GithubRepositoryTemplateSpecV1Dto {
    /**
     * 
     * @type {GithubRepositoryTemplateSpecV1DefinitionDto}
     * @memberof GithubRepositoryTemplateSpecV1Dto
     */
    definition: GithubRepositoryTemplateSpecV1DefinitionDto;
}

/**
 * Check if a given object implements the GithubRepositoryTemplateSpecV1Dto interface.
 */
export function instanceOfGithubRepositoryTemplateSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "definition" in value;

    return isInstance;
}

export function GithubRepositoryTemplateSpecV1DtoFromJSON(json: any): GithubRepositoryTemplateSpecV1Dto {
    return GithubRepositoryTemplateSpecV1DtoFromJSONTyped(json, false);
}

export function GithubRepositoryTemplateSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GithubRepositoryTemplateSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'definition': GithubRepositoryTemplateSpecV1DefinitionDtoFromJSON(json['definition']),
    };
}

export function GithubRepositoryTemplateSpecV1DtoToJSON(value?: GithubRepositoryTemplateSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'definition': GithubRepositoryTemplateSpecV1DefinitionDtoToJSON(value.definition),
    };
}

