/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RevisionTemplateSpecV1HealthCheckConfigDto
 */
export interface RevisionTemplateSpecV1HealthCheckConfigDto {
    /**
     * 
     * @type {number}
     * @memberof RevisionTemplateSpecV1HealthCheckConfigDto
     */
    initialDelaySeconds: number;
    /**
     * 
     * @type {number}
     * @memberof RevisionTemplateSpecV1HealthCheckConfigDto
     */
    periodSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof RevisionTemplateSpecV1HealthCheckConfigDto
     */
    timeoutSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof RevisionTemplateSpecV1HealthCheckConfigDto
     */
    failureThreshold: number;
    /**
     * 
     * @type {number}
     * @memberof RevisionTemplateSpecV1HealthCheckConfigDto
     */
    successThreshold: number;
}

/**
 * Check if a given object implements the RevisionTemplateSpecV1HealthCheckConfigDto interface.
 */
export function instanceOfRevisionTemplateSpecV1HealthCheckConfigDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "initialDelaySeconds" in value;
    isInstance = isInstance && "periodSeconds" in value;
    isInstance = isInstance && "timeoutSeconds" in value;
    isInstance = isInstance && "failureThreshold" in value;
    isInstance = isInstance && "successThreshold" in value;

    return isInstance;
}

export function RevisionTemplateSpecV1HealthCheckConfigDtoFromJSON(json: any): RevisionTemplateSpecV1HealthCheckConfigDto {
    return RevisionTemplateSpecV1HealthCheckConfigDtoFromJSONTyped(json, false);
}

export function RevisionTemplateSpecV1HealthCheckConfigDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RevisionTemplateSpecV1HealthCheckConfigDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'initialDelaySeconds': json['initialDelaySeconds'],
        'periodSeconds': json['periodSeconds'],
        'timeoutSeconds': json['timeoutSeconds'],
        'failureThreshold': json['failureThreshold'],
        'successThreshold': json['successThreshold'],
    };
}

export function RevisionTemplateSpecV1HealthCheckConfigDtoToJSON(value?: RevisionTemplateSpecV1HealthCheckConfigDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'initialDelaySeconds': value.initialDelaySeconds,
        'periodSeconds': value.periodSeconds,
        'timeoutSeconds': value.timeoutSeconds,
        'failureThreshold': value.failureThreshold,
        'successThreshold': value.successThreshold,
    };
}

