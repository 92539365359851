/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceStatusV1Dto
 */
export interface ServiceStatusV1Dto {
    /**
     * 
     * @type {string}
     * @memberof ServiceStatusV1Dto
     */
    githubRepositoryUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceStatusV1Dto
     */
    url?: string;
}

/**
 * Check if a given object implements the ServiceStatusV1Dto interface.
 */
export function instanceOfServiceStatusV1Dto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceStatusV1DtoFromJSON(json: any): ServiceStatusV1Dto {
    return ServiceStatusV1DtoFromJSONTyped(json, false);
}

export function ServiceStatusV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceStatusV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'githubRepositoryUrl': !exists(json, 'githubRepositoryUrl') ? undefined : json['githubRepositoryUrl'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function ServiceStatusV1DtoToJSON(value?: ServiceStatusV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'githubRepositoryUrl': value.githubRepositoryUrl,
        'url': value.url,
    };
}

