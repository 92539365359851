/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DatabaseSpecV1Dto } from './DatabaseSpecV1Dto';
import {
    DatabaseSpecV1DtoFromJSON,
    DatabaseSpecV1DtoFromJSONTyped,
    DatabaseSpecV1DtoToJSON,
} from './DatabaseSpecV1Dto';
import type { ResourceStatusV1Dto } from './ResourceStatusV1Dto';
import {
    ResourceStatusV1DtoFromJSON,
    ResourceStatusV1DtoFromJSONTyped,
    ResourceStatusV1DtoToJSON,
} from './ResourceStatusV1Dto';

/**
 * 
 * @export
 * @interface DatabaseV1Dto
 */
export interface DatabaseV1Dto {
    /**
     * Database Id is a unique identifier of a database within the application that the orchestrator provisions; it can be a kafka topic name for EMP integration or a harbor project name for container registry integration.
     * @type {string}
     * @memberof DatabaseV1Dto
     */
    readonly id?: string;
    /**
     * revisionId is a unique identifier of a resource revision. It is set by the server and returned in 
     * the response. If the client set this value during update, then the server validates against the last known 
     * value. If the values are different, the server returns a 409 status code. This can be used to implement 
     * optimistic concurrency control: when two different clients try to update the same resource, they might
     * override each other's changes if they don't supply runId. The recommended way is to get the resource first, 
     * and then do update with the received runId and retry on 409 status code.
     * 
     * @type {string}
     * @memberof DatabaseV1Dto
     */
    revisionId?: string;
    /**
     * 
     * @type {DatabaseSpecV1Dto}
     * @memberof DatabaseV1Dto
     */
    spec: DatabaseSpecV1Dto;
    /**
     * 
     * @type {ResourceStatusV1Dto}
     * @memberof DatabaseV1Dto
     */
    status?: ResourceStatusV1Dto;
}

/**
 * Check if a given object implements the DatabaseV1Dto interface.
 */
export function instanceOfDatabaseV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "spec" in value;

    return isInstance;
}

export function DatabaseV1DtoFromJSON(json: any): DatabaseV1Dto {
    return DatabaseV1DtoFromJSONTyped(json, false);
}

export function DatabaseV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatabaseV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'revisionId': !exists(json, 'revisionId') ? undefined : json['revisionId'],
        'spec': DatabaseSpecV1DtoFromJSON(json['spec']),
        'status': !exists(json, 'status') ? undefined : ResourceStatusV1DtoFromJSON(json['status']),
    };
}

export function DatabaseV1DtoToJSON(value?: DatabaseV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'revisionId': value.revisionId,
        'spec': DatabaseSpecV1DtoToJSON(value.spec),
        'status': ResourceStatusV1DtoToJSON(value.status),
    };
}

