/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RevisionStatusV1InstancesInnerDto } from './RevisionStatusV1InstancesInnerDto';
import {
    RevisionStatusV1InstancesInnerDtoFromJSON,
    RevisionStatusV1InstancesInnerDtoFromJSONTyped,
    RevisionStatusV1InstancesInnerDtoToJSON,
} from './RevisionStatusV1InstancesInnerDto';
import type { RevisionStatusV1InstancesInnerEventsInnerDto } from './RevisionStatusV1InstancesInnerEventsInnerDto';
import {
    RevisionStatusV1InstancesInnerEventsInnerDtoFromJSON,
    RevisionStatusV1InstancesInnerEventsInnerDtoFromJSONTyped,
    RevisionStatusV1InstancesInnerEventsInnerDtoToJSON,
} from './RevisionStatusV1InstancesInnerEventsInnerDto';

/**
 * 
 * @export
 * @interface RevisionStatusV1Dto
 */
export interface RevisionStatusV1Dto {
    /**
     * 
     * @type {Date}
     * @memberof RevisionStatusV1Dto
     */
    scheduledForDeletionDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RevisionStatusV1Dto
     */
    actualDeletionDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof RevisionStatusV1Dto
     */
    readyReplicas?: number;
    /**
     * 
     * @type {number}
     * @memberof RevisionStatusV1Dto
     */
    revisionNumber?: number;
    /**
     * 
     * @type {Array<RevisionStatusV1InstancesInnerDto>}
     * @memberof RevisionStatusV1Dto
     */
    instances?: Array<RevisionStatusV1InstancesInnerDto>;
    /**
     * 
     * @type {string}
     * @memberof RevisionStatusV1Dto
     */
    state?: RevisionStatusV1DtoStateEnum;
    /**
     * 
     * @type {Array<RevisionStatusV1InstancesInnerEventsInnerDto>}
     * @memberof RevisionStatusV1Dto
     */
    events?: Array<RevisionStatusV1InstancesInnerEventsInnerDto>;
}


/**
 * @export
 */
export const RevisionStatusV1DtoStateEnum = {
    Pending: 'PENDING',
    Healthy: 'HEALTHY',
    Failing: 'FAILING',
    NoInstances: 'NO_INSTANCES',
    Unknown: 'UNKNOWN'
} as const;
export type RevisionStatusV1DtoStateEnum = typeof RevisionStatusV1DtoStateEnum[keyof typeof RevisionStatusV1DtoStateEnum];


/**
 * Check if a given object implements the RevisionStatusV1Dto interface.
 */
export function instanceOfRevisionStatusV1Dto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RevisionStatusV1DtoFromJSON(json: any): RevisionStatusV1Dto {
    return RevisionStatusV1DtoFromJSONTyped(json, false);
}

export function RevisionStatusV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RevisionStatusV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scheduledForDeletionDate': !exists(json, 'scheduledForDeletionDate') ? undefined : (new Date(json['scheduledForDeletionDate'])),
        'actualDeletionDate': !exists(json, 'actualDeletionDate') ? undefined : (new Date(json['actualDeletionDate'])),
        'readyReplicas': !exists(json, 'readyReplicas') ? undefined : json['readyReplicas'],
        'revisionNumber': !exists(json, 'revisionNumber') ? undefined : json['revisionNumber'],
        'instances': !exists(json, 'instances') ? undefined : ((json['instances'] as Array<any>).map(RevisionStatusV1InstancesInnerDtoFromJSON)),
        'state': !exists(json, 'state') ? undefined : json['state'],
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(RevisionStatusV1InstancesInnerEventsInnerDtoFromJSON)),
    };
}

export function RevisionStatusV1DtoToJSON(value?: RevisionStatusV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scheduledForDeletionDate': value.scheduledForDeletionDate === undefined ? undefined : (value.scheduledForDeletionDate.toISOString()),
        'actualDeletionDate': value.actualDeletionDate === undefined ? undefined : (value.actualDeletionDate.toISOString()),
        'readyReplicas': value.readyReplicas,
        'revisionNumber': value.revisionNumber,
        'instances': value.instances === undefined ? undefined : ((value.instances as Array<any>).map(RevisionStatusV1InstancesInnerDtoToJSON)),
        'state': value.state,
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(RevisionStatusV1InstancesInnerEventsInnerDtoToJSON)),
    };
}

