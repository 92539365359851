/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Defines the environment class, allowed values are PROD, NONPROD and GLOBAL (for environment unaware or shared resources)
 * @export
 */
export const EnvironmentClassV1Dto = {
    Prod: 'PROD',
    Nonprod: 'NONPROD',
    Global: 'GLOBAL'
} as const;
export type EnvironmentClassV1Dto = typeof EnvironmentClassV1Dto[keyof typeof EnvironmentClassV1Dto];


export function EnvironmentClassV1DtoFromJSON(json: any): EnvironmentClassV1Dto {
    return EnvironmentClassV1DtoFromJSONTyped(json, false);
}

export function EnvironmentClassV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvironmentClassV1Dto {
    return json as EnvironmentClassV1Dto;
}

export function EnvironmentClassV1DtoToJSON(value?: EnvironmentClassV1Dto | null): any {
    return value as any;
}

