/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EnvVarValueTypeDto = {
    Static: 'STATIC',
    SecretKey: 'SECRET_KEY'
} as const;
export type EnvVarValueTypeDto = typeof EnvVarValueTypeDto[keyof typeof EnvVarValueTypeDto];


export function EnvVarValueTypeDtoFromJSON(json: any): EnvVarValueTypeDto {
    return EnvVarValueTypeDtoFromJSONTyped(json, false);
}

export function EnvVarValueTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvVarValueTypeDto {
    return json as EnvVarValueTypeDto;
}

export function EnvVarValueTypeDtoToJSON(value?: EnvVarValueTypeDto | null): any {
    return value as any;
}

