/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDto } from './ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDto';
import {
    ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDtoFromJSON,
    ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDtoFromJSONTyped,
    ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDtoToJSON,
} from './ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDto';
import type { ServiceQuickstartPipelineSpecV1ServiceTemplateDto } from './ServiceQuickstartPipelineSpecV1ServiceTemplateDto';
import {
    ServiceQuickstartPipelineSpecV1ServiceTemplateDtoFromJSON,
    ServiceQuickstartPipelineSpecV1ServiceTemplateDtoFromJSONTyped,
    ServiceQuickstartPipelineSpecV1ServiceTemplateDtoToJSON,
} from './ServiceQuickstartPipelineSpecV1ServiceTemplateDto';

/**
 * 
 * @export
 * @interface ServiceQuickstartPipelineSpecV1Dto
 */
export interface ServiceQuickstartPipelineSpecV1Dto {
    /**
     * 
     * @type {ServiceQuickstartPipelineSpecV1ServiceTemplateDto}
     * @memberof ServiceQuickstartPipelineSpecV1Dto
     */
    serviceTemplate: ServiceQuickstartPipelineSpecV1ServiceTemplateDto;
    /**
     * 
     * @type {ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDto}
     * @memberof ServiceQuickstartPipelineSpecV1Dto
     */
    githubRepositoryTemplate: ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDto;
    /**
     * 
     * @type {string}
     * @memberof ServiceQuickstartPipelineSpecV1Dto
     */
    region: string;
}

/**
 * Check if a given object implements the ServiceQuickstartPipelineSpecV1Dto interface.
 */
export function instanceOfServiceQuickstartPipelineSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "serviceTemplate" in value;
    isInstance = isInstance && "githubRepositoryTemplate" in value;
    isInstance = isInstance && "region" in value;

    return isInstance;
}

export function ServiceQuickstartPipelineSpecV1DtoFromJSON(json: any): ServiceQuickstartPipelineSpecV1Dto {
    return ServiceQuickstartPipelineSpecV1DtoFromJSONTyped(json, false);
}

export function ServiceQuickstartPipelineSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceQuickstartPipelineSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceTemplate': ServiceQuickstartPipelineSpecV1ServiceTemplateDtoFromJSON(json['serviceTemplate']),
        'githubRepositoryTemplate': ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDtoFromJSON(json['githubRepositoryTemplate']),
        'region': json['region'],
    };
}

export function ServiceQuickstartPipelineSpecV1DtoToJSON(value?: ServiceQuickstartPipelineSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceTemplate': ServiceQuickstartPipelineSpecV1ServiceTemplateDtoToJSON(value.serviceTemplate),
        'githubRepositoryTemplate': ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDtoToJSON(value.githubRepositoryTemplate),
        'region': value.region,
    };
}

