/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { APIVersionV1Dto } from './APIVersionV1Dto';
import {
    APIVersionV1DtoFromJSON,
    APIVersionV1DtoFromJSONTyped,
    APIVersionV1DtoToJSON,
} from './APIVersionV1Dto';
import type { InfraBrokerSpecV1Dto } from './InfraBrokerSpecV1Dto';
import {
    InfraBrokerSpecV1DtoFromJSON,
    InfraBrokerSpecV1DtoFromJSONTyped,
    InfraBrokerSpecV1DtoToJSON,
} from './InfraBrokerSpecV1Dto';
import type { InfraBrokerStatusV1Dto } from './InfraBrokerStatusV1Dto';
import {
    InfraBrokerStatusV1DtoFromJSON,
    InfraBrokerStatusV1DtoFromJSONTyped,
    InfraBrokerStatusV1DtoToJSON,
} from './InfraBrokerStatusV1Dto';
import type { ObjectMetaV1Dto } from './ObjectMetaV1Dto';
import {
    ObjectMetaV1DtoFromJSON,
    ObjectMetaV1DtoFromJSONTyped,
    ObjectMetaV1DtoToJSON,
} from './ObjectMetaV1Dto';

/**
 * 
 * @export
 * @interface InfraBrokerV1Dto
 */
export interface InfraBrokerV1Dto {
    /**
     * 
     * @type {APIVersionV1Dto}
     * @memberof InfraBrokerV1Dto
     */
    apiVersion: APIVersionV1Dto;
    /**
     * 
     * @type {string}
     * @memberof InfraBrokerV1Dto
     */
    kind: InfraBrokerV1DtoKindEnum;
    /**
     * 
     * @type {ObjectMetaV1Dto}
     * @memberof InfraBrokerV1Dto
     */
    metadata: ObjectMetaV1Dto;
    /**
     * 
     * @type {InfraBrokerSpecV1Dto}
     * @memberof InfraBrokerV1Dto
     */
    spec: InfraBrokerSpecV1Dto;
    /**
     * 
     * @type {InfraBrokerStatusV1Dto}
     * @memberof InfraBrokerV1Dto
     */
    status?: InfraBrokerStatusV1Dto;
}


/**
 * @export
 */
export const InfraBrokerV1DtoKindEnum = {
    InfraBroker: 'InfraBroker'
} as const;
export type InfraBrokerV1DtoKindEnum = typeof InfraBrokerV1DtoKindEnum[keyof typeof InfraBrokerV1DtoKindEnum];


/**
 * Check if a given object implements the InfraBrokerV1Dto interface.
 */
export function instanceOfInfraBrokerV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "apiVersion" in value;
    isInstance = isInstance && "kind" in value;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "spec" in value;

    return isInstance;
}

export function InfraBrokerV1DtoFromJSON(json: any): InfraBrokerV1Dto {
    return InfraBrokerV1DtoFromJSONTyped(json, false);
}

export function InfraBrokerV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfraBrokerV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiVersion': APIVersionV1DtoFromJSON(json['apiVersion']),
        'kind': json['kind'],
        'metadata': ObjectMetaV1DtoFromJSON(json['metadata']),
        'spec': InfraBrokerSpecV1DtoFromJSON(json['spec']),
        'status': !exists(json, 'status') ? undefined : InfraBrokerStatusV1DtoFromJSON(json['status']),
    };
}

export function InfraBrokerV1DtoToJSON(value?: InfraBrokerV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apiVersion': APIVersionV1DtoToJSON(value.apiVersion),
        'kind': value.kind,
        'metadata': ObjectMetaV1DtoToJSON(value.metadata),
        'spec': InfraBrokerSpecV1DtoToJSON(value.spec),
        'status': InfraBrokerStatusV1DtoToJSON(value.status),
    };
}

