/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PipelineStatusV1StepsInnerDto } from './PipelineStatusV1StepsInnerDto';
import {
    PipelineStatusV1StepsInnerDtoFromJSON,
    PipelineStatusV1StepsInnerDtoFromJSONTyped,
    PipelineStatusV1StepsInnerDtoToJSON,
} from './PipelineStatusV1StepsInnerDto';

/**
 * 
 * @export
 * @interface PipelineStatusV1Dto
 */
export interface PipelineStatusV1Dto {
    /**
     * 
     * @type {string}
     * @memberof PipelineStatusV1Dto
     */
    state: PipelineStatusV1DtoStateEnum;
    /**
     * 
     * @type {Date}
     * @memberof PipelineStatusV1Dto
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PipelineStatusV1Dto
     */
    endTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof PipelineStatusV1Dto
     */
    durationMillis?: number;
    /**
     * 
     * @type {Array<PipelineStatusV1StepsInnerDto>}
     * @memberof PipelineStatusV1Dto
     */
    steps: Array<PipelineStatusV1StepsInnerDto>;
}


/**
 * @export
 */
export const PipelineStatusV1DtoStateEnum = {
    Queued: 'QUEUED',
    Running: 'RUNNING',
    Pending: 'PENDING',
    Failed: 'FAILED',
    Completed: 'COMPLETED',
    Canceled: 'CANCELED'
} as const;
export type PipelineStatusV1DtoStateEnum = typeof PipelineStatusV1DtoStateEnum[keyof typeof PipelineStatusV1DtoStateEnum];


/**
 * Check if a given object implements the PipelineStatusV1Dto interface.
 */
export function instanceOfPipelineStatusV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "steps" in value;

    return isInstance;
}

export function PipelineStatusV1DtoFromJSON(json: any): PipelineStatusV1Dto {
    return PipelineStatusV1DtoFromJSONTyped(json, false);
}

export function PipelineStatusV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineStatusV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': json['state'],
        'startTime': !exists(json, 'startTime') ? undefined : (new Date(json['startTime'])),
        'endTime': !exists(json, 'endTime') ? undefined : (new Date(json['endTime'])),
        'durationMillis': !exists(json, 'durationMillis') ? undefined : json['durationMillis'],
        'steps': ((json['steps'] as Array<any>).map(PipelineStatusV1StepsInnerDtoFromJSON)),
    };
}

export function PipelineStatusV1DtoToJSON(value?: PipelineStatusV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
        'startTime': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'endTime': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
        'durationMillis': value.durationMillis,
        'steps': ((value.steps as Array<any>).map(PipelineStatusV1StepsInnerDtoToJSON)),
    };
}

