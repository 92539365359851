/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResourceStateV1Dto } from './ResourceStateV1Dto';
import {
    ResourceStateV1DtoFromJSON,
    ResourceStateV1DtoFromJSONTyped,
    ResourceStateV1DtoToJSON,
} from './ResourceStateV1Dto';

/**
 * 
 * @export
 * @interface ResourceStatusV1Dto
 */
export interface ResourceStatusV1Dto {
    /**
     * 
     * @type {Date}
     * @memberof ResourceStatusV1Dto
     */
    readonly createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ResourceStatusV1Dto
     */
    readonly lastModifiedDate: Date;
    /**
     * 
     * @type {ResourceStateV1Dto}
     * @memberof ResourceStatusV1Dto
     */
    stateCode: ResourceStateV1Dto;
    /**
     * Human readable description of the `stateCode`
     * @type {string}
     * @memberof ResourceStatusV1Dto
     */
    stateMessage: string;
}

/**
 * Check if a given object implements the ResourceStatusV1Dto interface.
 */
export function instanceOfResourceStatusV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "lastModifiedDate" in value;
    isInstance = isInstance && "stateCode" in value;
    isInstance = isInstance && "stateMessage" in value;

    return isInstance;
}

export function ResourceStatusV1DtoFromJSON(json: any): ResourceStatusV1Dto {
    return ResourceStatusV1DtoFromJSONTyped(json, false);
}

export function ResourceStatusV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceStatusV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': (new Date(json['createdDate'])),
        'lastModifiedDate': (new Date(json['lastModifiedDate'])),
        'stateCode': ResourceStateV1DtoFromJSON(json['stateCode']),
        'stateMessage': json['stateMessage'],
    };
}

export function ResourceStatusV1DtoToJSON(value?: ResourceStatusV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stateCode': ResourceStateV1DtoToJSON(value.stateCode),
        'stateMessage': value.stateMessage,
    };
}

