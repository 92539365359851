/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InfraBrokerSpecV1ConstraintsDto } from './InfraBrokerSpecV1ConstraintsDto';
import {
    InfraBrokerSpecV1ConstraintsDtoFromJSON,
    InfraBrokerSpecV1ConstraintsDtoFromJSONTyped,
    InfraBrokerSpecV1ConstraintsDtoToJSON,
} from './InfraBrokerSpecV1ConstraintsDto';

/**
 * 
 * @export
 * @interface InfraBrokerSpecV1Dto
 */
export interface InfraBrokerSpecV1Dto {
    /**
     * 
     * @type {string}
     * @memberof InfraBrokerSpecV1Dto
     */
    uri: string;
    /**
     * 
     * @type {InfraBrokerSpecV1ConstraintsDto}
     * @memberof InfraBrokerSpecV1Dto
     */
    constraints: InfraBrokerSpecV1ConstraintsDto;
}

/**
 * Check if a given object implements the InfraBrokerSpecV1Dto interface.
 */
export function instanceOfInfraBrokerSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uri" in value;
    isInstance = isInstance && "constraints" in value;

    return isInstance;
}

export function InfraBrokerSpecV1DtoFromJSON(json: any): InfraBrokerSpecV1Dto {
    return InfraBrokerSpecV1DtoFromJSONTyped(json, false);
}

export function InfraBrokerSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfraBrokerSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uri': json['uri'],
        'constraints': InfraBrokerSpecV1ConstraintsDtoFromJSON(json['constraints']),
    };
}

export function InfraBrokerSpecV1DtoToJSON(value?: InfraBrokerSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uri': value.uri,
        'constraints': InfraBrokerSpecV1ConstraintsDtoToJSON(value.constraints),
    };
}

