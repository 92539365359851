/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RevisionSpecV1AllOfSecretGroupRefsDto
 */
export interface RevisionSpecV1AllOfSecretGroupRefsDto {
    /**
     * 
     * @type {string}
     * @memberof RevisionSpecV1AllOfSecretGroupRefsDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionSpecV1AllOfSecretGroupRefsDto
     */
    version: number;
}

/**
 * Check if a given object implements the RevisionSpecV1AllOfSecretGroupRefsDto interface.
 */
export function instanceOfRevisionSpecV1AllOfSecretGroupRefsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "version" in value;

    return isInstance;
}

export function RevisionSpecV1AllOfSecretGroupRefsDtoFromJSON(json: any): RevisionSpecV1AllOfSecretGroupRefsDto {
    return RevisionSpecV1AllOfSecretGroupRefsDtoFromJSONTyped(json, false);
}

export function RevisionSpecV1AllOfSecretGroupRefsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RevisionSpecV1AllOfSecretGroupRefsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'version': json['version'],
    };
}

export function RevisionSpecV1AllOfSecretGroupRefsDtoToJSON(value?: RevisionSpecV1AllOfSecretGroupRefsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'version': value.version,
    };
}

