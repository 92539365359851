/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ObjectRefV1Dto } from './ObjectRefV1Dto';
import {
    ObjectRefV1DtoFromJSON,
    ObjectRefV1DtoFromJSONTyped,
    ObjectRefV1DtoToJSON,
} from './ObjectRefV1Dto';

/**
 * 
 * @export
 * @interface ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDto
 */
export interface ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDto {
    /**
     * 
     * @type {ObjectRefV1Dto}
     * @memberof ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDto
     */
    githubRepositoryTemplateRef: ObjectRefV1Dto;
}

/**
 * Check if a given object implements the ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDto interface.
 */
export function instanceOfApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "githubRepositoryTemplateRef" in value;

    return isInstance;
}

export function ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDtoFromJSON(json: any): ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDto {
    return ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDtoFromJSONTyped(json, false);
}

export function ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'githubRepositoryTemplateRef': ObjectRefV1DtoFromJSON(json['githubRepositoryTemplateRef']),
    };
}

export function ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDtoToJSON(value?: ApplicationQuickstartPipelineSpecV1GithubRepositoryTemplateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'githubRepositoryTemplateRef': ObjectRefV1DtoToJSON(value.githubRepositoryTemplateRef),
    };
}

