/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Reference to schema
 * @export
 * @interface ResourceRefV1Dto
 */
export interface ResourceRefV1Dto {
    /**
     * 
     * @type {string}
     * @memberof ResourceRefV1Dto
     */
    applicationCode: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceRefV1Dto
     */
    resourceId: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceRefV1Dto
     */
    readonly name?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceRefV1Dto
     */
    resourceType: ResourceRefV1DtoResourceTypeEnum;
}


/**
 * @export
 */
export const ResourceRefV1DtoResourceTypeEnum = {
    Topic: 'TOPIC',
    Schema: 'SCHEMA',
    Account: 'ACCOUNT'
} as const;
export type ResourceRefV1DtoResourceTypeEnum = typeof ResourceRefV1DtoResourceTypeEnum[keyof typeof ResourceRefV1DtoResourceTypeEnum];


/**
 * Check if a given object implements the ResourceRefV1Dto interface.
 */
export function instanceOfResourceRefV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "applicationCode" in value;
    isInstance = isInstance && "resourceId" in value;
    isInstance = isInstance && "resourceType" in value;

    return isInstance;
}

export function ResourceRefV1DtoFromJSON(json: any): ResourceRefV1Dto {
    return ResourceRefV1DtoFromJSONTyped(json, false);
}

export function ResourceRefV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceRefV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationCode': json['applicationCode'],
        'resourceId': json['resourceId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'resourceType': json['resourceType'],
    };
}

export function ResourceRefV1DtoToJSON(value?: ResourceRefV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationCode': value.applicationCode,
        'resourceId': value.resourceId,
        'resourceType': value.resourceType,
    };
}

