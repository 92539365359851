/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDto
 */
export interface GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDto {
    /**
     * 
     * @type {boolean}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDto
     */
    enabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDto
     */
    port?: number;
    /**
     * 
     * @type {string}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDto
     */
    path?: string;
}

/**
 * Check if a given object implements the GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDto interface.
 */
export function instanceOfGithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "enabled" in value;

    return isInstance;
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDtoFromJSON(json: any): GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDto {
    return GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDtoFromJSONTyped(json, false);
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'path': !exists(json, 'path') ? undefined : json['path'],
    };
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDtoToJSON(value?: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'port': value.port,
        'path': value.path,
    };
}

