/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DatabaseV1Dto } from './DatabaseV1Dto';
import {
    DatabaseV1DtoFromJSON,
    DatabaseV1DtoFromJSONTyped,
    DatabaseV1DtoToJSON,
} from './DatabaseV1Dto';

/**
 * 
 * @export
 * @interface DatabasePageV1Dto
 */
export interface DatabasePageV1Dto {
    /**
     * 
     * @type {number}
     * @memberof DatabasePageV1Dto
     */
    number: number;
    /**
     * 
     * @type {number}
     * @memberof DatabasePageV1Dto
     */
    size: number;
    /**
     * 
     * @type {number}
     * @memberof DatabasePageV1Dto
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof DatabasePageV1Dto
     */
    totalElements: number;
    /**
     * 
     * @type {boolean}
     * @memberof DatabasePageV1Dto
     */
    first: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DatabasePageV1Dto
     */
    last: boolean;
    /**
     * 
     * @type {Array<DatabaseV1Dto>}
     * @memberof DatabasePageV1Dto
     */
    content: Array<DatabaseV1Dto>;
}

/**
 * Check if a given object implements the DatabasePageV1Dto interface.
 */
export function instanceOfDatabasePageV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "totalPages" in value;
    isInstance = isInstance && "totalElements" in value;
    isInstance = isInstance && "first" in value;
    isInstance = isInstance && "last" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function DatabasePageV1DtoFromJSON(json: any): DatabasePageV1Dto {
    return DatabasePageV1DtoFromJSONTyped(json, false);
}

export function DatabasePageV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatabasePageV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'size': json['size'],
        'totalPages': json['totalPages'],
        'totalElements': json['totalElements'],
        'first': json['first'],
        'last': json['last'],
        'content': ((json['content'] as Array<any>).map(DatabaseV1DtoFromJSON)),
    };
}

export function DatabasePageV1DtoToJSON(value?: DatabasePageV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'size': value.size,
        'totalPages': value.totalPages,
        'totalElements': value.totalElements,
        'first': value.first,
        'last': value.last,
        'content': ((value.content as Array<any>).map(DatabaseV1DtoToJSON)),
    };
}

