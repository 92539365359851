/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EnvironmentClassV1Dto } from './EnvironmentClassV1Dto';
import {
    EnvironmentClassV1DtoFromJSON,
    EnvironmentClassV1DtoFromJSONTyped,
    EnvironmentClassV1DtoToJSON,
} from './EnvironmentClassV1Dto';
import type { ResourceRefV1Dto } from './ResourceRefV1Dto';
import {
    ResourceRefV1DtoFromJSON,
    ResourceRefV1DtoFromJSONTyped,
    ResourceRefV1DtoToJSON,
} from './ResourceRefV1Dto';
import type { SchemaTypeV1Dto } from './SchemaTypeV1Dto';
import {
    SchemaTypeV1DtoFromJSON,
    SchemaTypeV1DtoFromJSONTyped,
    SchemaTypeV1DtoToJSON,
} from './SchemaTypeV1Dto';
import type { TagV1Dto } from './TagV1Dto';
import {
    TagV1DtoFromJSON,
    TagV1DtoFromJSONTyped,
    TagV1DtoToJSON,
} from './TagV1Dto';

/**
 * 
 * @export
 * @interface SchemaV1Dto
 */
export interface SchemaV1Dto {
    /**
     * Retina resource ID
     * 
     * @type {string}
     * @memberof SchemaV1Dto
     */
    readonly id?: string;
    /**
     * The human-readable name of the resource.
     * @type {string}
     * @memberof SchemaV1Dto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaV1Dto
     */
    description?: string;
    /**
     * Code of application which owns this resource.
     * @type {string}
     * @memberof SchemaV1Dto
     */
    readonly applicationCode: string;
    /**
     * revisionId is a unique identifier of a resource revision.
     * 
     * @type {string}
     * @memberof SchemaV1Dto
     */
    readonly revisionId?: string;
    /**
     * A list of tags identifying the set of credentials' usage context. Provides additional context to the credentials and enhances searchability.
     * 
     * @type {Array<TagV1Dto>}
     * @memberof SchemaV1Dto
     */
    tags?: Array<TagV1Dto>;
    /**
     * 
     * @type {EnvironmentClassV1Dto}
     * @memberof SchemaV1Dto
     */
    environmentClass?: EnvironmentClassV1Dto;
    /**
     * 
     * @type {Array<ResourceRefV1Dto>}
     * @memberof SchemaV1Dto
     */
    readonly topics?: Array<ResourceRefV1Dto>;
    /**
     * 
     * @type {SchemaTypeV1Dto}
     * @memberof SchemaV1Dto
     */
    type: SchemaTypeV1Dto;
    /**
     * The schema for the element in the topic.
     * 
     * @type {string}
     * @memberof SchemaV1Dto
     */
    content: string;
}

/**
 * Check if a given object implements the SchemaV1Dto interface.
 */
export function instanceOfSchemaV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "applicationCode" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function SchemaV1DtoFromJSON(json: any): SchemaV1Dto {
    return SchemaV1DtoFromJSONTyped(json, false);
}

export function SchemaV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchemaV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'applicationCode': json['applicationCode'],
        'revisionId': !exists(json, 'revisionId') ? undefined : json['revisionId'],
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagV1DtoFromJSON)),
        'environmentClass': !exists(json, 'environmentClass') ? undefined : EnvironmentClassV1DtoFromJSON(json['environmentClass']),
        'topics': !exists(json, 'topics') ? undefined : ((json['topics'] as Array<any>).map(ResourceRefV1DtoFromJSON)),
        'type': SchemaTypeV1DtoFromJSON(json['type']),
        'content': json['content'],
    };
}

export function SchemaV1DtoToJSON(value?: SchemaV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagV1DtoToJSON)),
        'environmentClass': EnvironmentClassV1DtoToJSON(value.environmentClass),
        'type': SchemaTypeV1DtoToJSON(value.type),
        'content': value.content,
    };
}

