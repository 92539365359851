/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ObjectMetaV1Dto
 */
export interface ObjectMetaV1Dto {
    /**
     * 
     * @type {string}
     * @memberof ObjectMetaV1Dto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ObjectMetaV1Dto
     */
    application?: string;
    /**
     * 
     * @type {string}
     * @memberof ObjectMetaV1Dto
     */
    environment?: string;
    /**
     * 
     * @type {Date}
     * @memberof ObjectMetaV1Dto
     */
    readonly creationTimestamp?: Date;
}

/**
 * Check if a given object implements the ObjectMetaV1Dto interface.
 */
export function instanceOfObjectMetaV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function ObjectMetaV1DtoFromJSON(json: any): ObjectMetaV1Dto {
    return ObjectMetaV1DtoFromJSONTyped(json, false);
}

export function ObjectMetaV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ObjectMetaV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'application': !exists(json, 'application') ? undefined : json['application'],
        'environment': !exists(json, 'environment') ? undefined : json['environment'],
        'creationTimestamp': !exists(json, 'creationTimestamp') ? undefined : (new Date(json['creationTimestamp'])),
    };
}

export function ObjectMetaV1DtoToJSON(value?: ObjectMetaV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'application': value.application,
        'environment': value.environment,
    };
}

